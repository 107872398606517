// import { fetchingLangFromRMC } from '../configs/FireBaseConf';
import antdEnUS from 'antd/es/locale/en_US';
import antdViVN from 'antd/es/locale/vi_VN';
import antdLoLA from 'antd/es/locale/default';

import enLang from './en.json';
import viLang from './vi.json';
import loLang from './lo.json';

const fetchTranslations = async () => {
    try {
        // const res = await fetchingLangFromRMC();
        return {
            en: {
                antd: antdEnUS,
                locale: 'en-US',
                messages: {
                    // ...res["en"]
                    ...enLang
                }
            },
            vi: {
                antd: antdViVN,
                locale: 'vi-VN',
                messages: {
                    // ...res["vi"]
                    ...viLang
                }
            },
            lo: {
                antd: antdLoLA,
                locale: 'lo-LA',
                messages: {
                    // ...res["lo"]
                    ...loLang
                }
            }
        }
    } catch (error) {
        console.error('Error fetching translations:', error);
        return {};
    }
}

export default fetchTranslations;